<template>
  <div id="main">
    <div class="container-md">
      <i-navbar ref="nav"></i-navbar>
    </div>
    <div id="bookListPage" :class="{ 'd-none': pageType != 'list' }">
      <div class="container-fluid">
        <div id="topbar" class="row">
          <div class="col">
            <span
              type="button"
              class="d-inline-block mx-3 my-1"
              @click="pageType = 'book'"
              >書籍小傳</span
            >
            <span
              type="button"
              class="d-inline-block mx-3 my-1"
              @click="pageType = 'calligraphy'"
              >書畫作品</span
            >
          </div>
        </div>
        <div id="image_section" class="row">
          <div class="col-1">
            <img
              type="button"
              class="pre_btn"
              src="../assets/img/pre_btn.png"
              alt="..."
              @click="playImage('pre')"
            />
          </div>
          <div id="gallery" class="col my-3">
            <img
              v-for="item in imageSliderList"
              class="picture_style mx-2 d-inline-block mb-5"
              :src="item.imageUrl"
              alt="..."
              @click.prevent="openBookInfo('book', item.name)"
              type="button"
              :key="item.bookImageId"
            />
          </div>
          <div class="col-1">
            <img
              type="button"
              class="next_btn"
              src="../assets/img/next_btn.png"
              alt="..."
              @click="playImage('next')"
            />
          </div>
        </div>
      </div>

      <div class="container-md mt-4">
        <div id="feature_intro" class="text-left mb-3 ml-4">
          <span class="mr-4">
            <img
              src="../assets/img/fulltext_search_icon.png"
              alt="..."
            />檢索本書</span
          >
          <span class="mr-4">
            <img
              src="../assets/img/image_text_read.png"
              alt="..."
            />圖文對讀</span
          >
          <span class="mr-4">
            <img
              src="../assets/img/bookinfo_icon.png"
              alt="..."
            />書目資料</span
          >
        </div>
        <!-- table -->
        <div id="book_table" class="row text-left">
          <div class="col">
            <span
              >編 號
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('id')"
              ></i
            ></span>
          </div>
          <div class="col-3">
            <span
              >書 名
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('bookName')"
              ></i
            ></span>
          </div>
          <div class="col"><span>數位閱讀 </span></div>
          <div class="col">
            <span
              >作者朝代
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('authorDynasty')"
              ></i
            ></span>
          </div>
          <div class="col">
            <span
              >作者
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('author')"
              ></i
            ></span>
          </div>
          <div class="col">
            <span
              >出版年
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('publishYear')"
              ></i
            ></span>
          </div>
          <div class="col-2">
            <span
              >四庫分類
              <i
                class="fa fa-chevron-down sorting_icon"
                type="button"
                @click="sortBookList('bookClass')"
              ></i
            ></span>
            <div id="bookClassMenu" class="dropdown float-right">
              <a
                class="dropdown-toggle"
                id="dropdownMenuButton"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                選單
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-multicol"
                aria-labelledby="dropdownMenuButton"
              >
                <div class="dropdown-row">
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="經"
                      v-model="bookClassList"
                    />
                    經</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="史"
                      v-model="bookClassList"
                    />
                    史</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="子"
                      v-model="bookClassList"
                    />
                    子</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="集"
                      v-model="bookClassList"
                    />
                    集</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="叢書"
                      v-model="bookClassList"
                    />
                    叢書</label
                  >
                  <label class="dropdown-item"
                    ><input
                      type="checkbox"
                      value="書目"
                      v-model="bookClassList"
                    />
                    書目</label
                  >
                </div>

                <div class="dropdown-row text-right">
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="selectAllBookClass"
                    >全選</a
                  >
                  <a
                    class="dropdown-item text-center"
                    @click.prevent="clearSelectedBookClass"
                    >清除</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- book list -->
        <table id="bookList" class="table table-striped mt-1 text-left">
          <tbody class="row">
            <tr class="row" v-for="item in filterBookList" :key="item.pk">
              <th scope="row" class="col id_text_left">
                {{ item.bookId }}
              </th>
              <td class="col-3 book_text_left">
                <a href="#" @click.prevent="openBookInfo('book', item.name)">{{
                  item.name
                }}</a>
              </td>
              <td class="col feature_text_left">
                <img
                  type="button"
                  class="d-inline-block mr-2"
                  src="../assets/img/fulltext_search_icon.png"
                  alt="..."
                  @click="dbsearch('fulltext', item.name)"
                />
                <img
                  type="button"
                  class="d-inline-block mr-2"
                  src="../assets/img/image_text_read.png"
                  alt="..."
                  @click="openBookInfo('book', item.name, 'content')"
                  v-if="item.isContentEmpty === false"
                />
                <img
                  type="button"
                  class="d-inline-block"
                  src="../assets/img/bookinfo_icon.png"
                  alt="..."
                  @click="dbsearch('bookinfo', item.name)"
                />
              </td>
              <td class="col author_dynasty_text_left">
                {{ item.authorDynasty }}
              </td>
              <td class="col author_text_left">{{ item.author }}</td>
              <td class="col author_publish_left">{{ item.publishYear }}</td>
              <td class="col-2 author_bookclass_left">{{ item.bookClass }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      id="bookImage"
      class="container-fluid"
      :class="{ 'd-none': pageType != 'book' }"
    >
      <div id="topbar" class="row">
        <div class="col my-1">
          <div class="container-md">
            <div class="row">
              <div class="col-8 text-right">
                <div class="span_section">
                  <span
                    type="button"
                    class="d-inline-block mx-4"
                    :class="{ topbarActiveFont: pageType == 'book' }"
                    @click="pageType = 'book'"
                    >書籍小傳</span
                  >
                  <span
                    type="button"
                    class="d-inline-block mx-4"
                    @click="pageType = 'calligraphy'"
                    >書畫作品</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-md mt-5">
        <img
          id="left_extend_icon"
          type="button"
          src="../assets/img/left_extend_icon.png"
          alt="..."
          :class="{
            'd-none': bookContentExtend == true || bookImageExtend == true,
            'd-inline': bookContentExtend == false && bookImageExtend == false,
          }"
          @click="bookContentExtend = true"
        />
        <img
          id="right_extend_icon"
          type="button"
          :class="{
            'd-none': bookContentExtend == true || bookImageExtend == true,
            'd-inline': bookContentExtend == false && bookImageExtend == false,
          }"
          src="../assets/img/right_extend_icon.png"
          alt="..."
          @click="extendBookImage()"
        />
        <div class="row">
          <div class="col" v-if="bookContentExtend == false">
            <div class="letf_page">
              <div class="card_title">
                <span class="">書籍影像</span>
              </div>
              <div class="card_content_image mt-1">
                <div
                  type="button"
                  class="rounded-pill float-right py-1"
                  :class="{
                    toolbox_hide: bookImageExtend == false,
                    toolbox_hide_extend: bookImageExtend == true,
                  }"
                  v-if="toolboxStatus == 'hide'"
                  @click="toolboxStatus = 'show'"
                >
                  <span class="text-white font-weight-bold">工具列</span>
                </div>
                <div
                  class="rounded-pill float-right py-1"
                  :class="{
                    toolbox_show: bookImageExtend == false,
                    toolbox_show_extend: bookImageExtend == true,
                  }"
                  v-if="toolboxStatus == 'show'"
                >
                  <i
                    type="button"
                    class="fa fa-chevron-right arrow_icon"
                    @click="toolboxStatus = 'hide'"
                  ></i>
                  <img
                    src="../assets/img/add_size.png"
                    alt="..."
                    type="button"
                    class="mx-1"
                    @click="scaleImage('add')"
                  />
                  <img
                    src="../assets/img/minus_size.png"
                    alt="..."
                    type="button"
                    class="mx-1"
                    @click="scaleImage('minus')"
                  />
                  <img
                    src="../assets/img/origin_size.png"
                    alt="..."
                    type="button"
                    class="mx-1"
                    @click="scaleImage('origin')"
                  />
                  <img
                    src="../assets/img/fit_size.png"
                    alt="..."
                    type="button"
                    class="mx-1"
                    @click="scaleImage('fitFrame')"
                  />
                  <select class="mx-1" v-model="bookImageWidthSelect">
                    <option value="100" selected>100%</option>
                    <option value="120">120%</option>
                    <option value="140">140%</option>
                  </select>
                </div>

                <div class="image_section">
                  <img
                    src="../assets/img/pre_btn.png"
                    alt="..."
                    type="button"
                    @click="changeImage('pre')"
                  />
                  <div class="d-inline-block">
                    <img
                      ref="book_image"
                      :src="bookInfo.imageUrls[currBookImageIndex]"
                      alt="..."
                    />
                  </div>
                  <div
                    id="right_book_image"
                    class="d-inline-block"
                    v-if="bookImageExtend == true"
                  >
                    <img
                      ref="right_book_image"
                      :src="bookInfo.imageUrls[currBookImageIndex + 1]"
                      alt="..."
                    />
                  </div>
                  <img
                    src="../assets/img/next_btn.png"
                    alt="..."
                    type="button"
                    @click="changeImage('next')"
                  />
                  <img
                    id="left_extended_icon"
                    type="button"
                    :class="{
                      'd-none': bookImageExtend == false,
                      'd-inline': bookImageExtend == true,
                    }"
                    src="../assets/img/left_extend_icon.png"
                    alt="..."
                    @click="hideBookImage()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-3" v-if="bookImageExtend == false">
            <img
              id="right_extended_icon"
              type="button"
              v-if="bookContentExtend == true"
              src="../assets/img/right_extend_icon.png"
              alt="..."
              @click="extendBookContent()"
            />
            <div class="right_page">
              <div
                class="mb-1"
                :class="{
                  card_title: cardContentType == 'introduction',
                  card_footer: cardContentType != 'introduction',
                }"
                type="button"
                @click="cardContentType = 'introduction'"
              >
                <span class="">書籍小傳</span>
              </div>
              <div
                class="mb-1"
                :class="{
                  card_title: cardContentType == 'content',
                  card_footer: cardContentType != 'content',
                }"
                v-if="['content', 'reference'].includes(cardContentType)"
                type="button"
                @click="cardContentType = 'content'"
              >
                <span class="">圖文對讀</span>
              </div>
              <div
                class="card_title"
                v-if="cardContentType == 'reference'"
                type="button"
                @click="cardContentType = 'reference'"
              >
                <span class="">參考書目</span>
              </div>
              <div class="card_content_text mt-1 text-left p-4">
                <div class="row">
                  <div class="col-9 text-left">
                    <span class="title" v-if="cardContentType != 'reference'">
                      {{ bookInfo.title }}</span
                    >
                    <span class="title" v-if="cardContentType == 'reference'"
                      >參考書目</span
                    >
                    <div
                      v-if="cardContentType == 'introduction'"
                      class="d-inline"
                    >
                      <img
                        id="fulltext_search_icon"
                        type="button"
                        class="d-inline-block mx-3"
                        src="../assets/img/fulltext_search_icon.png"
                        alt="..."
                        @click="dbsearch('fulltext', bookInfo.title)"
                      />
                      <img
                        id="bookinfo_search_icon"
                        type="button"
                        class="d-inline-block"
                        src="../assets/img/bookinfo_icon.png"
                        alt="..."
                        @click="dbsearch('bookinfo', bookInfo.title)"
                      />
                    </div>
                  </div>
                  <div class="col-3 text-right">
                    <a
                      href="#"
                      class="m-1"
                      type="button"
                      style="font-size: 20px"
                      :class="{ active: curr_content_font_size_active[0] }"
                      @click.prevent="setContentSize(0, '20px')"
                      >A</a
                    >
                    <a
                      href="#"
                      class="m-1"
                      style="font-size: 18px"
                      :class="{ active: curr_content_font_size_active[1] }"
                      @click.prevent="setContentSize(1, '18px')"
                      >A</a
                    >
                    <a
                      href="#"
                      class="m-1"
                      style="font-size: 16px"
                      :class="{ active: curr_content_font_size_active[2] }"
                      @click.prevent="setContentSize(2, '16px')"
                      >A</a
                    >
                  </div>
                </div>

                <div class="row">
                  <div class="col" :style="{ 'font-size': contentFontSize }">
                    <p
                      v-html="bookInfo.introduction"
                      v-if="cardContentType == 'introduction'"
                    ></p>
                    <p
                      v-html="bookInfo.content"
                      v-if="cardContentType == 'content'"
                    ></p>
                    <p
                      v-html="bookInfo.ref"
                      v-if="cardContentType == 'reference'"
                    ></p>
                  </div>
                </div>
              </div>

              <div
                class="card_footer mb-1"
                v-if="!['content', 'reference'].includes(cardContentType)"
                type="button"
                @click="cardContentType = 'content'"
              >
                <span class="">圖文對讀</span>
              </div>
              <div
                class="card_footer"
                v-if="cardContentType != 'reference'"
                type="button"
                @click="cardContentType = 'reference'"
              >
                <span class="">參考書目</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mt-5 vertical_line"
        v-if="bookContentExtend == false && bookImageExtend == false"
      ></div>
    </div>

    <div
      id="calligraphy"
      class="container-fluid"
      :class="{ 'd-none': pageType != 'calligraphy' }"
    >
      <div id="topbar" class="row">
        <div class="col my-1">
          <div class="container-md">
            <div class="row">
              <div class="col-8 text-right">
                <div class="span_section">
                  <span
                    type="button"
                    class="d-inline-block mx-4"
                    @click="pageType = 'book'"
                    >書籍小傳</span
                  >
                  <span
                    type="button"
                    class="d-inline-block mx-4"
                    :class="{ topbarActiveFont: pageType == 'calligraphy' }"
                    @click="pageType = 'calligraphy'"
                    >書畫作品</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="calligraphy_section" class="container-md mt-3">
        <div
          class="card mb-3 calligraphy_object"
          v-for="item in calligraphyList"
          :key="item.title"
        >
          <div class="row no-gutters">
            <div id="calligraphy_image" class="col-md-4">
              <a :href="item.original_image_url" target="_blank">
                <img :src="item.image_url" class="card-img" alt="..." />
              </a>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ item.title }}</h5>
                <p class="card-text" v-html="item.content"></p>
                <p class="card-text" v-html="item.detail_link"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      id="go_back_btn"
      type="button"
      class="btn btn-info"
      v-if="pageType != 'list'"
      @click="
        pageType = 'list';
        cardContentType = 'introduction';
      "
    >
      回到珍藏列表
    </button>
    <i-footer></i-footer>
  </div>
</template>

<script>
/* eslint-disable */

import $ from 'jquery';
import navbar from '../components/Navbar.vue';
import footer from '../components/Footer.vue';

export default {
  mounted() {
    this.$refs.nav.isActiveList = [0, 0, 0, 0, 0, 0, 0, 0];
    this.$refs.nav.isActiveList[4] = 1;

    $(document).ready(() => {
      $('.dropdown-menu').on('click', (e) => {
        // avoid dropdown menu close when click item
        e.stopPropagation();
      });
    });

    const bookListAPI = `${process.env.VUE_APP_APIPATH}api/booklist/`;
    this.$http.get(bookListAPI).then((response) => {
      console.log(response.data);
      this.bookList = response.data;
      this.bookList.forEach((item, index) => {
        const bookInfoAPI = `${process.env.VUE_APP_APIPATH}api/bookinfo/?bookname=${item.name}&format=json`;
        const itemCopy = item;
        this.$http.get(bookInfoAPI).then((response) => {
          itemCopy.isContentEmpty = this.bookInfo.content === '';
        });
        this.bookList[index] = itemCopy;
      });
      if (this.$route.query.bookname) {
        // console.log('hi', this.$route.query.bookname);
        this.openBookInfo('book', this.$route.query.bookname, 'content');
      } else {
        this.bookInfo = { ...response.data[0] };
        this.openBookInfo('list', this.bookList[0].name);
      }
      this.updateFilterBookList();
    });

    const calligraphyListAPI = `${process.env.VUE_APP_APIPATH}api/calligraphy-list/`;
    this.$http.get(calligraphyListAPI).then((response) => {
      this.calligraphyList = response.data;
      this.calligraphyList.forEach((item, index) => {
        this.calligraphyList[index].image_url = encodeURI(
          `${process.env.VUE_APP_IMAGE_APIPATH}treasure/${item.image_url}`,
        );
      });
    });

    this.loadBookImage();
  },
  components: {
    'i-navbar': navbar,
    'i-footer': footer,
  },
  data() {
    return {
      contentFontSize: '20px',
      curr_content_font_size_active: [1, 0, 0],
      pageType: '',
      pageTypeList: ['list', 'book', 'calligraphy'],
      cardContentType: 'introduction',
      cardContentTypeList: ['introduction', 'content', 'reference'],
      bookContentExtend: false,
      bookImageExtend: false,
      bookClassList: [],
      filterBookList: [],
      bookImageWidth: 100,
      bookImageWidthSelect: 100,
      bookImageAngle: 0,
      originalBookImageSizeList: [],
      toolboxStatus: 'hide',
      bookList: [
        {
          id: 1,
          name: '郭氏玄中記',
          authorDynasty: '晉',
          author: '郭  璞',
          publishYear: '1893',
          bookClass: '子',
        },
        {
          id: 2,
          name: '山海經圖贊',
          authorDynasty: '晉',
          author: '郭  璞',
          publishYear: '1895',
          bookClass: '史',
        },
        {
          id: 3,
          name: '爾雅圖贊 ',
          authorDynasty: '晉',
          author: '郭  璞',
          publishYear: '1895',
          bookClass: '經',
        },
        {
          id: 4,
          name: '翼教叢編 ',
          authorDynasty: '清',
          author: '葉德輝',
          publishYear: '1898',
          bookClass: '子',
        },
        {
          id: 5,
          name: '郭氏玄中記 ',
          authorDynasty: '晉',
          author: '郭  璞',
          publishYear: '1893',
          bookClass: '子',
        },
      ],
      currBookImageIndex: 0,
      bookInfo: {
        title: '《郭氏玄中記》',
        content:
          '<p>晉郭璞撰，清葉德輝輯，1893年出版。本書屬早期志怪小說之代表作，上承《山海經》，下啟六朝志怪，由地理、民族、歷史、物產、文化等方面建構出一個瑰麗恢宏的「異世界」，成為繼張華《博物志》後，另一博物地理體的代表作。明人始錄此書佚文數條，直至清代方進行有系統的輯佚工作。版本方面，葉德輝視茆泮林、馬國翰輯本「挂漏甚多」，致力於改善前人成果。為避過往輯引單一、紛雜之弊，他將佚文完整者置首，並取疑義部分附於文末，其目的為「俾讀者一覽周知，可以省覆檢之力」。藉此傳統的輯存方式，不僅將解釋的主導權交予讀者，亦拓寬文獻的觀察視域。 </p>',
        ref: '<p>1. 劉苑如：〈題名、輯佚與復原－－《玄中記》的異世界構想〉，《中國文哲研究集刊》第31期（2007年9月），頁29-74。</p><p>2. 陽清：〈《玄中記》輯本考述〉，《圖書館理論與實踐》2013年第6期，頁63-67。</p><p>3. 百度百科：玄中記 <a href="https://baike.baidu.com/item/%E7%8E%84%E4%B8%AD%E8%AE%B0/2244233" target="_blank">https://baike.baidu.com/item/%E7%8E%84%E4%B8%AD%E8%AE%B0/2244233</a></p>',
        imageUrls: [
          'https://i5tfyw.ch.files.1drv.com/y4mbNy6lTi_ELpW16ruDVfYtQwzFceDYXUUQZxFqJ4IAfT9zQuhM6nTBToITZQV5ZPE3eatCaIUwXMYUL_JO4F6q9Gl3YqgvHpp0iF3qp-1ltDkbCj6U6TQkyoXi_sEDYoYUkzUuXg_A3uaFAHci-87-gWjd_btQQM9F2B2BtFXjAEnYQkuw5-WuI8BvZ_6D7mgcb-UsEAGScImd1zFYKillA/02%E9%83%AD%E6%B0%8F%E7%8E%84%E4%B8%AD%E8%A8%98_%E9%A0%81%E9%9D%A2_1.jpg?psid=1',
          'https://jptfyw.ch.files.1drv.com/y4muoOOdGJ2_wYUUr2S71FoocQ7QErlTUxM7bL7cuNMNpIeU422YtThLP3OJZPcRut9KXt-qvxiMq6u6yzKPDe3zAqkBmdVMaWhRES4vlSyOJ6J7wsc72znXWId5mjSA3jr2A0fnTT6TrSPJd32uF3TznbOn2MyvE0LsoJfrmHC7xtizJjXyLI2R5vGZHs9scGspmFd3Jv4Ple-kdH6pCgFQA/02%E9%83%AD%E6%B0%8F%E7%8E%84%E4%B8%AD%E8%A8%98_%E9%A0%81%E9%9D%A2_3.jpg?psid=1',
        ],
      },
      calligraphyList: [
        {
          title: '葉德輝致梁鼎芬信札',
          content: '',
          image_url:
            'https://jzt0bq.ch.files.1drv.com/y4m3ZAS6uBhCEcP4DM_MDLSy2Q2LWL5TuNE7pm3YYTZQeJ2Ce3mAt-3HRiykJqRba5BkV5HHky1zeZsc0McOQYT60XOLrfWwmp2qFvr1iMkVwFIIr9zmXtaBsdHTqyNgW2UqticLNBjYUMJMGKQkVkFTkMextqGOiSyA8kiBwisUKMo8hMaIC0sPwatuH3WjE68YDHFS5z51cnwq6g2WlyNEQ/3-2%20%E8%A7%80%E5%8F%A4%E8%97%8F%E7%8F%8D(%E6%9B%B8%E6%B3%95%E4%BD%9C%E5%93%81).png?psid=1',
          detail_link: '#',
        },
        {
          title: '葉德輝手書團扇面',
          content: '',
          image_url:
            'https://ijt0bq.ch.files.1drv.com/y4mG46MvCuqE-ymzpSWiW_qGjpNAPwfaB847uKnbnK0p_gn4EnYx_0L3DXQsBVR1H-lKuOJdvLgyBOhy5YWqCmjmmZNelQgLHJbTIuhVj5niT6vUoaMEKm5PbNClEbtpp-PYO_Yc7tVEzxoYJxCjkHdGzOf_SFqZlrW6YuWyfXbwuO4bwcUchfDztRCe6fue4mvVpBx8fovbxUXtx7SD-Xuhg/3-2%20%E8%A7%80%E5%8F%A4%E8%97%8F%E7%8F%8D(%E6%9B%B8%E6%B3%95%E4%BD%9C%E5%93%81)%20(1).png?psid=1',
          detail_link: '#',
        },
        {
          title: '葉德輝手書團扇面',
          content: '',
          image_url:
            '  https://h5t0bq.ch.files.1drv.com/y4mDlDzhtwIr3cTtC_N9S_0l7jK9lz8Cgmuw7fP1NDO8ZVDyYh_4bZ-tmt2DQlO9skK1JSVGIhieSnR6kbv14QvMC3Pt27uKaIl9nfJJwqcjS0n-BQbCQ_BZTaX7yPMAB9x5gYVbumFDcNShRQcnCe0n5cFdQwn4yPLKmg20FJgv09lkb0RQWkH9pzUyyl_Py3mH0_h0mYBpdTH2Oe4WtrM8w/3-2%20%E8%A7%80%E5%8F%A4%E8%97%8F%E7%8F%8D(%E6%9B%B8%E6%B3%95%E4%BD%9C%E5%93%81)%20(2).png?psid=1',
          detail_link: '#',
        },
      ],
      imageSliderIndex: 0,
      imageSliderList: [
        {
          bookImageId: 1,
          name: '觀古堂藏書目',
          imageUrl:
            'https://i5simw.ch.files.1drv.com/y4mh6WqgrxjBRst71SDBrlBWl5sThh02NlMWWni1eJXH_6y9OIr9ungNunwSgCUAv_cRQ9Z1CIT6yWWX97FyR_EDYXPE7Rpk0DNJbOuUdNTbdp75a7uETLhI0nFYG4J0RYq8qrh15FSzQd9lVP2jRaQuDZuc8GXcENrf9AIFim3DulLJPw9SFZEb5YReuuywt69rzT4Lh3iQXT2jieZJB9P7Q/treasure_page_image_1_modify.png?psid=1',
        },
        {
          bookImageId: 2,
          name: '三教源流搜神大全',
          imageUrl:
            'https://jzsimw.ch.files.1drv.com/y4mbieyoaaEGGhPvIWrjblEHVCq1bDl_r4wXM4tXGE0qsJTe62hdIbakVdokHg33kCJj66JpJMr8a5ISKMupapibHbS3nWUhXXbJZObGcAEsIUEVHN3jfNfWXhr6csCiCggvQqIUjDyDGWC3tpEw1uYYUk5Bz6EZv4NBH7BkZYVQHWzEo3HFuTLSOpDapuHyvvnoIApcaAQ2Tm92d7p3eBCOg/treasure_page_image_2_modify.png?psid=1',
        },
        {
          bookImageId: 3,
          name: '華陽陶隱居集',
          imageUrl:
            'https://i5qjqa.ch.files.1drv.com/y4mj2sg9STrkYfKtTxBl6AdQmVCXVlVxtEvpYr0HY8FRCWZirmY5IJKZ6zF1xXPTuk3UbW9PaSFxnImj8Wscsqe6r0I0-328XlHeFi8XRGHnHRPIarLlxu3DI4LXJmEPlZ8EGhxDh1XUVRkpoawQSx8Se86CPmg7u9PS-env-7ofHYddao5ZKC68leXQj6OcATz5dBBduqm1KZtzZyk0EdFpA/treasure_page_image_3_modify.jpg?psid=1',
        },
        {
          bookImageId: 4,
          name: '南嶽總勝集',
          imageUrl:
            'https://ipsimw.ch.files.1drv.com/y4mgnIjlUabljm3naFGmvzIMm9oBNuBXPBgBfGosktL_Usaw_mP5SIgSENCYQPIkltprNoDzHp0ra1wJwAFHrwWiPg3Em76hZtQwTazhwU2NoXFQRQHlW1OsfX1pGPjNWYAoKjSTsm7bb-4Uf5pUSmqujca5cUb3jflU-Ich8BpKQ4eF1C0BPetxpsWVXWt8S1uyw5rD6k6EKQ2vTNdlrZctg/treasure_page_image_4_modify.png?psid=1',
        },
        {
          bookImageId: 5,
          name: '唐女郎魚玄機詩',
          imageUrl:
            'https://ljsimw.ch.files.1drv.com/y4m84FSYtjhNkhCzDVPcnwpieFKeN18Vf7s81FtJ8di8t4tCYRSzgpY9D6t0kJV1zy8ipZFTHLmo8NAda2v1EfD4D3yBnGNt7sIdyBKCs8VDe3S1IaKOn4e19JwSXqglGWP-AYLp02myowdcKMOcXqYjEBQVMtahw93kv9McW_D9cx5a-Px2Tyl1JQEF1em7TzRvIcg1V0S8VApXF4dEvzFkA/treasure_page_image_5_modify.png?psid=1',
        },
      ],
    };
  },
  watch: {
    bookClassList: {
      handler() {
        this.updateFilterBookList();
      },
      deep: true,
    },
    bookImageWidthSelect: {
      handler() {
        this.scaleImage('select');
      },
      deep: true,
    },
  },
  methods: {
    clearSelectedBookClass() {
      this.bookClassList = [];
    },
    selectAllBookClass() {
      this.bookClassList = ['經', '史', '子', '集'];
    },
    updateFilterBookList() {
      if (this.bookClassList.length === 0) {
        this.filterBookList = { ...this.bookList };
      } else {
        this.filterBookList = [];
        this.bookList.forEach((book) => {
          this.bookClassList.forEach((bookClass) => {
            if (book.bookClass.includes(bookClass)) {
              this.filterBookList.push(book);
            }
          });
        });
      }
    },
    scaleImage(option) {
      if (option === 'add') {
        const imgWidth = this.bookImageWidth;
        this.bookImageWidth = imgWidth * 1.2;
        this.$refs.book_image.style.width = `${this.bookImageWidth}%`;
        this.$refs.right_book_image.style.width = `${this.bookImageWidth}%`;
      } else if (option === 'minus') {
        const imgWidth = this.bookImageWidth;
        if (imgWidth * 0.8 > 100) {
          this.bookImageWidth = imgWidth * 0.8;
        }
        this.$refs.book_image.style.width = `${this.bookImageWidth}%`;
        this.$refs.right_book_image.style.width = `${this.bookImageWidth}%`;
      } else if (option === 'fitFrame') {
        this.bookImageWidth = 100;
        this.$refs.book_image.style.width = `${this.bookImageWidth}%`;
        this.$refs.book_image.style.height = 'auto';
        if (this.bookImageExtend) {
          setTimeout(() => {
            this.bookImageWidth = 100;
            this.$refs.right_book_image.style.width = `${this.bookImageWidth}%`;
            this.$refs.right_book_image.style.height = 'auto';
          }, 500);
        }
      } else if (option === 'select') {
        this.bookImageWidth = this.bookImageWidthSelect;
        this.$refs.book_image.style.width = `${this.bookImageWidth}%`;
        this.$refs.book_image.style.height = 'auto';
        if (this.bookImageExtend) {
          setTimeout(() => {
            this.bookImageWidth = this.bookImageWidthSelect;
            this.$refs.right_book_image.style.width = `${this.bookImageWidth}%`;
            this.$refs.right_book_image.style.height = 'auto';
          }, 500);
        }
      } else if (option === 'origin') {
        const currImageIndex = this.currBookImageIndex;
        this.$refs.book_image.style.width = `${this.originalBookImageSizeList[currImageIndex][0]}px`;
        this.$refs.book_image.style.height = `${this.originalBookImageSizeList[currImageIndex][1]}px`;
        // console.log(this.$refs.book_image.style.width, this.$refs.book_image.style.height);
        if (this.bookImageExtend) {
          setTimeout(() => {
            let currImageIndexNext = this.currBookImageIndex + 1;
            currImageIndexNext =
              currImageIndexNext % this.originalBookImageSizeList.length;
            this.$refs.right_book_image.style.width = `${this.originalBookImageSizeList[currImageIndexNext][0]}px`;
            this.$refs.right_book_image.style.height = `${this.originalBookImageSizeList[currImageIndexNext][1]}px`;
          }, 500);
        }
      }
    },
    rotateImage(direct) {
      if (direct === 'left') {
        let angle = this.bookImageAngle;
        angle -= 90;
        if (angle < 0) {
          angle += 360;
        }
        this.bookImageAngle = angle % 360;
        this.$refs.book_image.style.transform = `rotate(${this.bookImageAngle}deg)`;
      } else if (direct === 'right') {
        let angle = this.bookImageAngle;
        angle += 90;
        this.bookImageAngle = angle % 360;
        this.$refs.book_image.style.transform = `rotate(${this.bookImageAngle}deg)`;
      }
    },
    changeImage(option) {
      if (option === 'pre') {
        let index = this.currBookImageIndex;
        index -= 1;
        if (index < 0) {
          index = this.bookInfo.imageUrls.length - 1;
        }
        this.currBookImageIndex = index;
      } else if (option === 'next') {
        let index = this.currBookImageIndex;
        index += 1;
        if (index >= this.bookInfo.imageUrls.length) {
          index = 0;
        }
        this.currBookImageIndex = index;
      }
    },
    setContentSize(activeIndex, fontSize) {
      this.contentFontSize = fontSize;
      this.curr_content_font_size_active = [0, 0, 0];
      this.curr_content_font_size_active[activeIndex] = 1;
    },
    openBookInfo(pageType, bookname, cardContentType) {
      const bookInfoAPI = `${process.env.VUE_APP_APIPATH}api/bookinfo/?bookname=${bookname}&format=json`;
      this.$http.get(bookInfoAPI).then((response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          this.originalBookImageSizeList = [];
          this.bookInfo = { ...response.data[0] };
          this.bookInfo.imageUrls = this.bookInfo.imageUrls.split('\n');
          this.bookInfo.imageUrls.forEach((item, index) => {
            this.bookInfo.imageUrls[
              index
            ] = `${process.env.VUE_APP_IMAGE_APIPATH}treasure/${item}`;
            this.getMeta(this.bookInfo.imageUrls[index]);
          });
          this.scaleImage('fitFrame');
          this.pageType = pageType;
          if (cardContentType) {
            this.cardContentType = cardContentType;
          }
        }
      });
    },
    getMeta(url) {
      const img = new Image();
      const vm = this;
      img.onload = function () {
        // console.log(this.width, this.height);
        vm.originalBookImageSizeList.push([this.width, this.height]);
      };
      img.src = url;
    },
    dbsearch(type, keyword) {
      const queryURL = `/dbsearch?type=${type}&keyword=${keyword}`;
      console.log(queryURL);
      this.$router.push(queryURL);
    },
    sortBookList(type) {
      this.filterBookList = { ...this.bookList };
      if (type === 'id') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) => {
            const firstBookId = parseInt(firstItem.bookId, 10);
            const secondBookId = parseInt(secondItem.bookId, 10);
            return firstBookId - secondBookId;
          });
      } else if (type === 'publishYear') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) => {
            if (secondItem.publishYear === '') {
              return -1;
            }
            const firstPublishYear = parseInt(firstItem.publishYear, 10);
            const secondPublishYear = parseInt(secondItem.publishYear, 10);
            return firstPublishYear - secondPublishYear;
          });
      } else if (type === 'bookClass') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) => {
            if (secondItem.bookClass === '') {
              return -1;
            }
            const firstItemScore = this.bookClassSorting(firstItem.bookClass);
            const secondItemScore = this.bookClassSorting(secondItem.bookClass);
            return firstItemScore - secondItemScore;
          });
      } else if (type === 'authorDynasty') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) => {
            const firstItemScore = this.authorDynastySorting(
              firstItem.authorDynasty,
            );
            const secondItemScore = this.authorDynastySorting(
              secondItem.authorDynasty,
            );
            return firstItemScore - secondItemScore;
          });
      } else if (type === 'bookName') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) =>
            firstItem.name.localeCompare(secondItem.name, 'zh-Hant'),
          );
      } else if (type === 'author') {
        this.filterBookList = [];
        this.filterBookList = this.bookList
          .slice()
          .sort((firstItem, secondItem) =>
            firstItem.author.localeCompare(secondItem.author, 'zh-Hant'),
          );
      }
    },
    bookClassSorting(bookClass) {
      if (bookClass === '') {
        return -1;
      }
      if (bookClass === '經 ') {
        return 0;
      }
      if (bookClass === '史') {
        return 1;
      }
      if (bookClass === '子') {
        return 2;
      }
      if (bookClass === '集') {
        return 3;
      }
      if (bookClass === '叢書') {
        return 4;
      }
      if (bookClass === '書目') {
        return 5;
      }
      return -1;
    },
    authorDynastySorting(dynasty) {
      const dynastyList = [
        '漢',
        '晉',
        '梁',
        '隋',
        '唐',
        '宋',
        '元',
        '明',
        '清',
        '',
      ];
      return dynastyList.indexOf(dynasty);
    },
    extendBookContent() {
      this.bookContentExtend = false;
      setTimeout(() => {
        this.scaleImage('fitFrame');
      }, 500);
    },
    extendBookImage() {
      this.bookImageExtend = true;
      setTimeout(() => {
        this.scaleImage('fitFrame');
      }, 500);
    },
    hideBookImage() {
      this.bookImageExtend = false;
    },
    playImage(operation) {
      if (operation === 'next') {
        this.imageSliderIndex += 1;
        const bookImageAPI = `${process.env.VUE_APP_APIPATH}book-images-count?book_image_index=${this.imageSliderIndex}`;
        // console.log(bookImageAPI);
        this.$http.get(bookImageAPI).then((response) => {
          // console.log('hi', response.data);
          this.imageSliderIndex = response.data;
        });
      } else if (operation === 'pre') {
        this.imageSliderIndex -= 1;
        if (this.imageSliderIndex < 0) {
          this.imageSliderIndex = 0;
        }
      }

      this.loadBookImage();
    },
    loadBookImage() {
      const bookImageAPI = `${process.env.VUE_APP_APIPATH}api/book-image/?book_image_index=${this.imageSliderIndex}`;
      console.log(bookImageAPI);
      this.$http.get(bookImageAPI).then((response) => {
        // console.log('hi', response.data);
        if (response.data.length > 0) {
          this.imageSliderList = [];
          this.imageSliderList = response.data;
          console.log(this.imageSliderList);
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
#right_book_image
  margin-left: 10rem

#left_extend_icon
  position: relative
  transform: scale(1) translate(-100%, 850%)
  z-index: 500

#right_extend_icon
  position: relative
  transform: scale(1) translate(100%, 850%)
  z-index: 500

#right_extended_icon
  position: relative
  transform: scale(1) translate(-3920%, 850%)
  z-index: 500

#left_extended_icon
  position: relative
  transform: scale(1) translate(400%, -750%)
  z-index: 500

#fulltext_search_icon
  transform: scale(1.3) translate(-0%, -11%)

#bookinfo_search_icon
  transform: scale(1.3) translate(-0%, -14%)

#feature_intro
  span
    font-size: 16px
    letter-spacing: 2px
    line-height: 21px
    color: #4c4c4b
    font-family: "Microsoft Jheng Hei Regular"
  img
    transform: scale(1.2) translate(-23%, -15%)

#topbar
  background-color: #9fcfce
  font-size: 14px
  letter-spacing: 1px
  color: #595757
  font-family: "Microsoft Jheng Hei Regular"
  .span_section
    padding-right: 5.4rem

#image_section
  background-color: #e4edee
  height: 50vh
  overflow: hidden
  .picture_style
    height: 45vh
    width: 30vh
    &:hover
      filter: drop-shadow(7.778px 7.778px 5px rgba(0,0,0,0.34))
      background-color: #000000
  .pre_btn
    position: relative
    height: 60px
    width: 60px
    transform: translate(130%, 460%)
    z-index: 500
  .next_btn
    height: 60px
    width: 60px
    transform: translate(-130%, 460%)
    z-index: 500

#book_table
  div
    border: 1px solid white
    background-color: #c3d3da
    span
      font-size: 16px
      letter-spacing: 2px
      line-height: 24px
      color: #1b566e
      font-weight: bold
      font-family: "Microsoft Jheng Hei Bold"
    .sorting_icon
      display: inline-block
      transform: scale(1) translate(-0%, -7%)

#bookClassMenu
  display: inline
  border: none !important

  .dropdown-row
    border: none !important

  .dropdown-multicol
    width: 20em

  .dropdown-item
    display: inline-block
    width: 33%
    font-size: 14px
    letter-spacing: 1px
    line-height: 24px
    color: #1b566e
    font-family: "Microsoft Jheng Hei Regular"

#bookList
  tbody
    display: block
    height: 700px
    overflow-x: hidden
    overflow-y: auto
    margin-bottom: 10rem
  .id_text_left
    padding-left: 32px
  .book_text_left
    padding-left: 20px
  .feature_text_left
    padding-left: 20px
  .author_dynasty_text_left
    padding-left: 20px
  .author_text_left
    padding-left: 20px
  .author_publish_left
    padding-left: 20px
  .author_bookclass_left
    padding-left: 20px

.topbarActiveFont
  color: #296464
  font-weight: bold

.card_title
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  background-color: #677b7b
  font-size: 16px
  letter-spacing: 2px
  line-height: 24px
  color: #ffffff
  font-family: "Microsoft Jheng Hei Regular"
  text-align: center

.card_footer
  border-radius: 8px
  background-color: #677b7b
  font-size: 16px
  letter-spacing: 2px
  line-height: 24px
  color: #ffffff
  font-family: "Microsoft Jheng Hei Regular"
  text-align: center

.card_content_image
  background-color: #dbe3e4
  height: 80vh
  margin-bottom: 3rem
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  .image_section
    padding-top: 5rem
    height: 70vh
    div
      height: 65vh
      width: 50vh
      overflow: auto

.vl
  border-left: 6px solid gray

.vertical_line
  content: ""
  position: absolute
  height: 87vh
  z-index: 0
  top: 5rem
  bottom: 0
  left: 51%
  border-left: 1px solid #b7b7b7
  transform: translate(-50%)

#go_back_btn
  position: fixed
  right: 0
  bottom: 0

.toolbox_hide
  background-color: #677b7b
  width: 30%
  margin-top: 0.5rem
  margin-right: 0.5rem

.toolbox_hide_extend
  background-color: #677b7b
  width: 10%
  margin-top: 0.5rem
  margin-right: 0.5rem

.toolbox_show
  background-color: #677b7b
  width: 55%
  margin-top: 0.5rem
  margin-right: 0.5rem
  .arrow_icon
    color: white
    transform: scale(1) translate(-70%, 12%)

.toolbox_show_extend
  background-color: #677b7b
  width: 30%
  margin-top: 0.5rem
  margin-right: 0.5rem
  .arrow_icon
    color: white
    transform: scale(1) translate(-70%, 12%)

.card_content_text
  background-color: #efefef
  height: 72vh
  overflow-x: hidden
  overflow-y: auto
  .title
    font-size: 20px
    letter-spacing: 2px
    line-height: 30px
    color: #231815
    font-weight: bold
    font-family: "Microsoft Jheng Hei Bold"
  a
    display: inline-block
    font-size: 22px
    letter-spacing: 2px
    line-height: 30px
    color: #6b6a6a
    font-family: "Microsoft Jheng Hei Regular"
  a.active
    background-color: #c2dada
    border-radius: 50%
    width: 30px
    height: 30px
    text-align: center

.calligraphy_object
  text-align: left
  letter-spacing: 2px
  line-height: 24px
  color: #677b7b
  font-family: "Microsoft Jheng Hei Regular"
  .content
    padding-top: 2rem
  span
    font-size: 18px
  p
    font-size: 16px
  a
    font-size: 16px
    color: #677b7b

#calligraphy_image
  display: flex
  align-items: center
  img
    padding: 10px
    width: 325px
    height: 215px

#calligraphy
  background-color: #f7f8f8

#bookImage
  background-color: #f7f8f8

#main
  height: 100vh
  min-width: 1200px
</style>

<style scoped>
* >>> .ingredient {
  color: red;
  font-size: 8px;
}
</style>
